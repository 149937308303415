import cn from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  TextLink = 'textLink',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  align?: 'center' | 'edges';
  buttonType?: ButtonType;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  darkBackground?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  align,
  buttonType,
  children,
  className,
  darkBackground,
  disabled = false,
  icon,
  iconPosition = 'left',
  ...props
}) => (
  <button
    {...props}
    className={cn(
      'box-border flex h-[56px] max-w-[400px] cursor-pointer items-center justify-center gap-2 rounded-xl text-base outline-none',
      {
        'border-none bg-raspberry text-white hover:bg-raspberry-hover focus:border-solid focus:border-raspberry-active active:bg-raspberry-active':
          buttonType === ButtonType.Primary && !disabled,
        'border-2 border-solid border-neutral-800 bg-white hover:border-3 focus:bg-neutral-50 active:border-2 active:border-neutral-300':
          buttonType === ButtonType.Secondary && !disabled,
        'bg-neutral-200 text-white': Boolean(disabled && buttonType !== ButtonType.TextLink),
        'text-nowrap border-none hover:text-raspberry focus:font-bold active:text-raspberry-active':
          buttonType === ButtonType.TextLink,
        'text-white': buttonType === ButtonType.TextLink && darkBackground,
        'text-neutral-800': buttonType === ButtonType.TextLink && !darkBackground,
        'border-none text-neutral-200': Boolean(disabled && buttonType === ButtonType.TextLink),
        'flex-row-reverse': iconPosition === 'right',
        'justify-between': align === 'edges',
        'w-full px-6': buttonType !== ButtonType.TextLink,
      },
      className
    )}
    disabled={disabled}>
    {Boolean(icon) && icon}
    <span className={cn({ underline: buttonType === ButtonType.TextLink })}>{children}</span>
  </button>
);

export default Button;
